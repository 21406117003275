import _d3Dsv from "d3-dsv";
var exports = {};

// https://d3js.org/d3-fetch/ v2.0.0 Copyright 2020 Mike Bostock
(function (global, factory) {
  factory(exports, _d3Dsv);
})(exports, function (exports, d3Dsv) {
  'use strict';

  function responseBlob(response) {
    if (!response.ok) throw new Error(response.status + " " + response.statusText);
    return response.blob();
  }

  function blob(input, init) {
    return fetch(input, init).then(responseBlob);
  }

  function responseArrayBuffer(response) {
    if (!response.ok) throw new Error(response.status + " " + response.statusText);
    return response.arrayBuffer();
  }

  function buffer(input, init) {
    return fetch(input, init).then(responseArrayBuffer);
  }

  function responseText(response) {
    if (!response.ok) throw new Error(response.status + " " + response.statusText);
    return response.text();
  }

  function text(input, init) {
    return fetch(input, init).then(responseText);
  }

  function dsvParse(parse) {
    return function (input, init, row) {
      if (arguments.length === 2 && typeof init === "function") row = init, init = undefined;
      return text(input, init).then(function (response) {
        return parse(response, row);
      });
    };
  }

  function dsv(delimiter, input, init, row) {
    if (arguments.length === 3 && typeof init === "function") row = init, init = undefined;
    var format = d3Dsv.dsvFormat(delimiter);
    return text(input, init).then(function (response) {
      return format.parse(response, row);
    });
  }

  var csv = dsvParse(d3Dsv.csvParse);
  var tsv = dsvParse(d3Dsv.tsvParse);

  function image(input, init) {
    return new Promise(function (resolve, reject) {
      var image = new Image();

      for (var key in init) image[key] = init[key];

      image.onerror = reject;

      image.onload = function () {
        resolve(image);
      };

      image.src = input;
    });
  }

  function responseJson(response) {
    if (!response.ok) throw new Error(response.status + " " + response.statusText);
    if (response.status === 204 || response.status === 205) return;
    return response.json();
  }

  function json(input, init) {
    return fetch(input, init).then(responseJson);
  }

  function parser(type) {
    return (input, init) => text(input, init).then(text => new DOMParser().parseFromString(text, type));
  }

  var xml = parser("application/xml");
  var html = parser("text/html");
  var svg = parser("image/svg+xml");
  exports.blob = blob;
  exports.buffer = buffer;
  exports.csv = csv;
  exports.dsv = dsv;
  exports.html = html;
  exports.image = image;
  exports.json = json;
  exports.svg = svg;
  exports.text = text;
  exports.tsv = tsv;
  exports.xml = xml;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const blob = exports.blob,
      buffer = exports.buffer,
      csv = exports.csv,
      dsv = exports.dsv,
      html = exports.html,
      image = exports.image,
      json = exports.json,
      svg = exports.svg,
      text = exports.text,
      tsv = exports.tsv,
      xml = exports.xml,
      __esModule = exports.__esModule;